import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Button, Image, Box, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Outdoor Stride Hub
			</title>
			<meta name={"description"} content={"Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари."} />
			<meta property={"og:title"} content={"Outdoor Stride Hub"} />
			<meta property={"og:description"} content={"Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMenu" />
			<Override slot="box1" />
			<Override slot="quarklycommunityKitMenuOverride" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride1" />
			<Override slot="text1" />
		</Components.Header>
		<Section text-align="center" padding="80px 0 0px 0" sm-padding="40px 0">
			<Text
				as="h1"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="left"
			>
				Outdoor Stride Hub
			</Text>
			<Text as="p" font="--headline3" margin="20px 0 0 0" text-align="left">
				Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари.
			</Text>
			<Button
				margin="2rem 0px 0px 0px"
				max-width="300px"
				box-shadow="--m"
				border-radius="3px"
				background="linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);"
				border-color="--color-dark"
				font="--base"
				color="--light"
				type="link"
				text-decoration-line="initial"
				href="/poslugi-ta-cini"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Послуги
				</Strong>
			</Button>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/6745953828b5e30023ae7020/images/4.png?v=2024-11-26T12:48:05.122Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/4.png?v=2024-11-26T12%3A48%3A05.122Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/4.png?v=2024-11-26T12%3A48%3A05.122Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/4.png?v=2024-11-26T12%3A48%3A05.122Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/4.png?v=2024-11-26T12%3A48%3A05.122Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/4.png?v=2024-11-26T12%3A48%3A05.122Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/4.png?v=2024-11-26T12%3A48%3A05.122Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/4.png?v=2024-11-26T12%3A48%3A05.122Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/6745953828b5e30023ae7020/images/Adult-Package.webp?v=2024-11-26T12:51:10.631Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/Adult-Package.webp?v=2024-11-26T12%3A51%3A10.631Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/Adult-Package.webp?v=2024-11-26T12%3A51%3A10.631Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/Adult-Package.webp?v=2024-11-26T12%3A51%3A10.631Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/Adult-Package.webp?v=2024-11-26T12%3A51%3A10.631Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/Adult-Package.webp?v=2024-11-26T12%3A51%3A10.631Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/Adult-Package.webp?v=2024-11-26T12%3A51%3A10.631Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/Adult-Package.webp?v=2024-11-26T12%3A51%3A10.631Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/6745953828b5e30023ae7020/images/5.png?v=2024-11-26T12:48:05.120Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/5.png?v=2024-11-26T12%3A48%3A05.120Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/5.png?v=2024-11-26T12%3A48%3A05.120Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/5.png?v=2024-11-26T12%3A48%3A05.120Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/5.png?v=2024-11-26T12%3A48%3A05.120Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/5.png?v=2024-11-26T12%3A48%3A05.120Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/5.png?v=2024-11-26T12%3A48%3A05.120Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/5.png?v=2024-11-26T12%3A48%3A05.120Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Незалежно від вашого рівня підготовки – новачок чи досвідчений екстремал – у нас є все необхідне, щоб зробити ваш зимовий відпочинок незабутнім.
				</Text>
				<Button
					margin="2rem 0px 0px 0px"
					max-width="300px"
					box-shadow="--m"
					border-radius="3px"
					background="linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);"
					border-color="--color-dark"
					font="--base"
					color="--light"
					type="link"
					text-decoration-line="initial"
					href="/poslugi-ta-cini"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Послуги
					</Strong>
				</Button>
			</Box>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/6745953828b5e30023ae7020/images/black-tie-ski-rentals.jpg?v=2024-11-26T12:48:05.146Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/black-tie-ski-rentals.jpg?v=2024-11-26T12%3A48%3A05.146Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/black-tie-ski-rentals.jpg?v=2024-11-26T12%3A48%3A05.146Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/black-tie-ski-rentals.jpg?v=2024-11-26T12%3A48%3A05.146Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/black-tie-ski-rentals.jpg?v=2024-11-26T12%3A48%3A05.146Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/black-tie-ski-rentals.jpg?v=2024-11-26T12%3A48%3A05.146Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/black-tie-ski-rentals.jpg?v=2024-11-26T12%3A48%3A05.146Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/black-tie-ski-rentals.jpg?v=2024-11-26T12%3A48%3A05.146Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					<Strong>
						Широкий вибір:
					</Strong>
					{" "}сучасне спорядження для будь-якого зимового виду спорту.
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/6745953828b5e30023ae7020/images/AgAAAB0Awm1LL5BIOXK640Z7msyzEw.jpeg?v=2024-11-26T12:48:05.131Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/AgAAAB0Awm1LL5BIOXK640Z7msyzEw.jpeg?v=2024-11-26T12%3A48%3A05.131Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/AgAAAB0Awm1LL5BIOXK640Z7msyzEw.jpeg?v=2024-11-26T12%3A48%3A05.131Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/AgAAAB0Awm1LL5BIOXK640Z7msyzEw.jpeg?v=2024-11-26T12%3A48%3A05.131Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/AgAAAB0Awm1LL5BIOXK640Z7msyzEw.jpeg?v=2024-11-26T12%3A48%3A05.131Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/AgAAAB0Awm1LL5BIOXK640Z7msyzEw.jpeg?v=2024-11-26T12%3A48%3A05.131Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/AgAAAB0Awm1LL5BIOXK640Z7msyzEw.jpeg?v=2024-11-26T12%3A48%3A05.131Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/AgAAAB0Awm1LL5BIOXK640Z7msyzEw.jpeg?v=2024-11-26T12%3A48%3A05.131Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					<Strong>
						Доступність:
					</Strong>
					{" "}гнучкі тарифи, які підходять кожному.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/6745953828b5e30023ae7020/images/ps_snowboard.jpg?v=2024-11-26T12:48:05.141Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/ps_snowboard.jpg?v=2024-11-26T12%3A48%3A05.141Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/ps_snowboard.jpg?v=2024-11-26T12%3A48%3A05.141Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/ps_snowboard.jpg?v=2024-11-26T12%3A48%3A05.141Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/ps_snowboard.jpg?v=2024-11-26T12%3A48%3A05.141Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/ps_snowboard.jpg?v=2024-11-26T12%3A48%3A05.141Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/ps_snowboard.jpg?v=2024-11-26T12%3A48%3A05.141Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953828b5e30023ae7020/images/ps_snowboard.jpg?v=2024-11-26T12%3A48%3A05.141Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					<Strong>
						Якість:
					</Strong>
					{" "}лише перевірені та безпечні моделі.
				</Text>
			</Box>
		</Section>
		<Section background="--color-darkL1" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ми знаходимося у самому серці Карпат
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Поряд із кращими лижними курортами.{" "}
					<br />
					Обираючи Outdoor Stride Hub, ви отримуєте доступ до високоякісного спорядження і команди, яка готова допомогти у будь-який момент.
				</Text>
				<Link
					href="mailto:blank?hello@company.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@outdoorstridehub.gmail
				</Link>
				<Link
					href="tel:1234567899"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0954691897
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Драгобрат, Закарпатська область, 90630
				</Text>
			</Box>
			<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
				Готові до зими? Забронюйте своє спорядження прямо{" "}
				<br />
				зараз і вирушайте у свою зимову подорож із комфортом!
			</Text>
			<Button
				max-width="300px"
				box-shadow="--m"
				border-radius="3px"
				background="linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);"
				border-color="--color-dark"
				font="--base"
				color="--light"
				type="link"
				text-decoration-line="initial"
				href="/poslugi-ta-cini"
				text-align="center"
				margin="0px auto 0px auto"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Послуги
				</Strong>
			</Button>
		</Section>
		<Components.Footer12>
			<Override slot="list" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});